<template>
	<div class="page page-blog">
		<Menu />
		
		<div class="content-wrapper">
			<div class="container">
			
				<div class="content" v-html="content"></div>
			</div>
		</div>
		
		<Footer />
	</div>
</template>

<script>
import Menu from "../components/Menu";
import Footer from "../components/Footer";

export default {
	components: {Menu, Footer},
	data() {
		return {
			blog: {}
		}
	},
	mounted() {
		this.getBlog()
	},
	methods:{
		getBlog() {
			const portalId = this.$store.getters.portal?.id
			
			const url = `${this.$config.API_URL}portal/${portalId}/gdpr`
			
			this.$axios.get(url)
				.then((res) => {
					console.warn('get blog', res.data)
					this.blog = res.data.data
				})
		}
	},
	computed: {
		content: {
			get() {
				return this.blog?.article
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.page-blog::v-deep {
	.content-wrapper {
		.content {
			margin: 30px 0;
		}
	}
}
</style>