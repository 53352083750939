<template>
	<div class="main-menu-wrapper">
		<div class="top-menu container">
			<div class="logo">
				<div @click="toHome">
					<img :src="portalLogo" alt="logo">
				</div>
			</div>
			
			<b-navbar-toggle class="d-lg-none" target="main-menu"></b-navbar-toggle>
			
			<div class="menu-contact">
				
				<div class="contact-item">
					<div class="icon">
						<img src="../assets/icons/adress.png" alt="adresa">
					</div>
					
					<div class="text">
						<span>Adresa</span>
						<a :href="'https://maps.google.com?q=' + portalAddress" target="_blank">{{ portalAddress }}</a>
					</div>
				</div>
				
				<div class="contact-item">
					<div class="icon">
						<img src="../assets/icons/mail.png" alt="email">
					</div>
					
					<div class="text">
						<span>E-mail</span>
						<a :href="'mailto:'+ portalEmail">{{ portalEmail }}</a>
					</div>
				</div>
				
				<div class="contact-item">
					<div class="icon">
						<img src="../assets/icons/phone.png" alt="mobil">
					</div>
					
					<div class="text">
						<span>Mobil</span>
						<a :href="'tel:' + portalPhone">{{ portalPhone }}</a>
					</div>
				</div>
				
			</div>
		</div>
		
		<div class="main-menu">
			
			<b-navbar class="container" toggleable="lg">
<!--				<b-navbar-brand class="d-xl-none"></b-navbar-brand>-->
<!--				<b-navbar-toggle target="main-menu"></b-navbar-toggle>-->
				
				<b-collapse id="main-menu" is-nav>
					<b-navbar-nav class="menu-items">
						<b-nav-item v-for="item in menuItems" :key="item.link" :href="item.link">
							<div v-if="item.icon" class="icon">
								<img :src="item.icon" :alt="item.title">
							</div>
							<div class="label">{{ item.title }}</div>
						</b-nav-item>
					</b-navbar-nav>
					
					<b-navbar-nav class="social-icons">
						<b-nav-item v-if="facebook" :href="facebook" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-facebook-f"></i>
							</div>
						</b-nav-item>
						<b-nav-item v-if="instagram" :href="instagram" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-instagram"></i>
							</div>
						</b-nav-item>
						<b-nav-item v-if="youtube" :href="youtube" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-youtube"></i>
							</div>
						</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			
			</b-navbar>
			
		</div>
		
	</div>
</template>

<script>
export default {
	methods: {
		toHome() {
			if (this.$route.name !== 'Home' || (this.$route.query.location || this.$route.query.category || this.$route.query.type)) {
				this.$router.push({name: 'Home'})
			} else {
				window.location.reload()
			}
		}
	},
	computed: {
		portalLogo: {
			get() {
				return this.$store.getters.portal?.logo
			}
		},
		portalAddress: {
			get() {
				return `${this.$store.getters.portal?.address?.street}, ${this.$store.getters.portal?.address?.zip_code} ${this.$store.getters.portal?.address?.city}`
			}
		},
		portalEmail: {
			get() {
				return `${this.$store.getters.portal?.contact?.email}`
			}
		},
		portalPhone: {
			get() {
				return `${this.$store.getters.portal?.contact?.phone}`
			}
		},
		menuItems: {
			get() {
				return this.$store.getters.portal?.menu
			}
		},
		facebook: {
			get() {
				return this.$store.getters.portal?.portal?.social?.facebook
			}
		},
		instagram: {
			get() {
				return this.$store.getters.portal?.portal?.social?.instagram
			}
		},
		youtube: {
			get() {
				return this.$store.getters.portal?.portal?.social?.youtube
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.main-menu-wrapper::v-deep {
	.top-menu {
		display: flex;
		flex-direction: row;
		
		.logo > div {
			//width: 380px;
			height: 100px;
			padding: 15px 10px;
			cursor: pointer;
			
			img {
				width: auto;
				height: 100%;
				object-fit: contain;
				object-position: left;
			}
		}
		
		.navbar-toggler {
			margin-left: auto;
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			
			.navbar-toggler-icon {
				height: 2em;
				width: 2em;
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
			}
		}
		
		.menu-contact {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: auto;
			
			.contact-item {
				display: flex;
				flex-direction: row;
				padding: 0 20px;
				
				&:not(:last-child) {
					border-right: 1px solid var(--reality-portal-color);
				}
				
				.icon {
					width: 32px;
					
					img {
						width: 100%;
						height: auto;
					}
				}
				
				.text {
					display: flex;
					flex-direction: column;
					margin-left: 10px;
					
					span {
						color: var(--reality-portal-color);
						font-weight: 600;
						font-size: 14px;
					}
					
					a {
						color: $black;
						font-size: 15px;
					}
				}
			}
		}
	}
	
	.main-menu {
		background: var(--reality-portal-color);
		
		.navbar {
			padding: 0 15px;
			
			#main-menu {
				.menu-items {
					.nav-item {
						display: flex;
						align-items: center;
						
						.nav-link {
							display: flex;
							flex-direction: row;
							align-items: center;
							padding: 20px 30px;
							
							.icon {
								width: 40px;
								
								img {
									width: 100%;
									height: auto;
								}
							}
							
							.label {
								margin-left: 5px;
								color: white;
							}
						}
						
					}
				}
				
				.social-icons {
					margin-left: auto;
					
					.icon {
						padding: 0 7px;
						
						i {
							color: white;
							font-size: 20px;
						}
					}
				}
				
			}
		}
	}
	
	@media (max-width: 1200px) {
		.top-menu {
			.logo > div {
				height: 80px;
			}
			
			.menu-contact {
				.contact-item {
					.text {
						span {
							font-size: 12px;
						}
						a {
							font-size: 11px;
						}
					}
				}
			}
		}
	}
	
	@media (max-width: 992px) {
		.top-menu {
			//.logo {
			//	height: 80px;
			//}
			
			.menu-contact {
				display: none;
			}
		}
		
		.main-menu {
			.navbar {
				#main-menu {
					.social-icons {
						display: flex;
						flex-direction: row;
						margin-top: 10px;
						margin-bottom: 30px;
						padding: 0 30px;
						
						.nav-item {
							.icon i {
								font-size: 22px;
							}
							
							&:not(:last-child) {
								margin-right: 15px;
							}
						}
					}
				}
			}
		}
	}
}
</style>