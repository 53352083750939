<template>
	<div class="footer-wrapper">
		<div class="footer-links-wrapper" v-html="footerLinks"></div>
<!--		<div class="footer-links">-->
<!--			<div class="container">-->
<!--				<div class="row">-->
<!--					<div class="col-lg-3">-->
<!--						<b-button variant="outline-light">TROLIGA.sk</b-button>-->
<!--					</div>-->
<!--					<div class="col-lg-3">-->
<!--						<b-button variant="outline-light">TROLIGABUS.sk</b-button>-->
<!--					</div>-->
<!--					<div class="col-lg-3">-->
<!--						<b-button variant="outline-light">SADPP.sk</b-button>-->
<!--					</div>-->
<!--					<div class="col-lg-3">-->
<!--						<b-button variant="outline-light">TROLIGAPARTS.sk</b-button>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->
		
		<footer>
			<div class="container">
				<div class="main-footer">
					<div class="logo">
						<img :src="portalLogo" alt="logo">
					</div>
					
					<div class="footer-contact">
						
						<div class="contact-item">
							<div class="icon">
								<img src="../assets/icons/adress_white.png" alt="adresa">
							</div>
							
							<div class="text">
								<span>Adresa</span>
								<a :href="'https://maps.google.com?q=' + portalAddress" target="_blank">{{ portalAddress }}</a>
							</div>
						</div>
						
						<div class="contact-item">
							<div class="icon">
								<img src="../assets/icons/mail_white.png" alt="email">
							</div>
							
							<div class="text">
								<span>E-mail</span>
								<a :href="'mailto:'+ portalEmail">{{ portalEmail }}</a>
							</div>
						</div>
						
						<div class="contact-item">
							<div class="icon">
								<img src="../assets/icons/phone_white.png" alt="mobil">
							</div>
							
							<div class="text">
								<span>Mobil</span>
								<a :href="'tel:' + portalPhone">{{ portalPhone }}</a>
							</div>
						</div>
					
					</div>
				</div>
				
				<div class="copyright-wrapper">
					<div class="social-icons">
						<p>Sledujte nás:</p>
						<a v-if="facebook" :href="facebook" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-facebook-f"></i>
							</div>
						</a>
						<a v-if="instagram" :href="instagram" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-instagram"></i>
							</div>
						</a>
						<a v-if="youtube" :href="youtube" target="_blank">
							<div class="icon">
								<i class="fa-brands fa-youtube"></i>
							</div>
						</a>
					</div>
					
					<div class="copyright">
						<p>Troligareality.sk &#169; {{ date }} Všetky práva vyhradené. </p>
						<div class="author">
							<p>Dizajn a programovanie:</p>
							<a href="https://wame.sk/e-shopove-riesenie-wame-admin-cms" target="_blank"><img src="../assets/wame_logo-white.png" alt="wame"></a>
						</div>
					</div>
				</div>
			</div>
		</footer>
		
	</div>
</template>

<script>
export default {
	data() {
		return {
			date: new Date().getFullYear(),
		}
	},
	computed: {
		footerLinks: {
			get() {
				return this.$store.getters.portal?.portal?.footer
			}
		},
		portalLogo: {
			get() {
				return this.$store.getters.portal?.logo_footer
			}
		},
		portalAddress: {
			get() {
				return `${this.$store.getters.portal?.address?.street}, ${this.$store.getters.portal?.address?.zip_code} ${this.$store.getters.portal?.address?.city}`
			}
		},
		portalEmail: {
			get() {
				return `${this.$store.getters.portal?.contact?.email}`
			}
		},
		portalPhone: {
			get() {
				return `${this.$store.getters.portal?.contact?.phone}`
			}
		},
		facebook: {
			get() {
				return this.$store.getters.portal?.portal?.social?.facebook
			}
		},
		instagram: {
			get() {
				return this.$store.getters.portal?.portal?.social?.instagram
			}
		},
		youtube: {
			get() {
				return this.$store.getters.portal?.portal?.social?.youtube
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.footer-wrapper::v-deep {
	.footer-links {
		background: var(--reality-portal-color);
		
		.btn {
			border-radius: 30px;
			margin: 25px 0;
			width: 100%;
			padding: 12px 10px;
			font-size: 17px;
			
			&:hover {
				color: var(--reality-portal-color);
			}
		}
	}
	
	footer {
		background: $black;
		border-top: 3px solid white;
		
		.main-footer {
			display: flex;
			flex-direction: row;
			padding: 30px 0;
			border-bottom: 1px solid white;
			
			.logo	{
				//width: 380px;
				height: 100px;
				padding: 15px 10px;
				
				img {
					width: auto;
					height: 100%;
					object-fit: contain;
					object-position: left;
				}
			}
			
			.footer-contact {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-left: auto;
				
				.contact-item {
					display: flex;
					flex-direction: row;
					padding: 0 20px;
					
					&:not(:last-child) {
						border-right: 1px solid $text-light;;
					}
					
					.icon {
						width: 32px;
						
						img {
							width: 100%;
							height: auto;
						}
					}
					
					.text {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						
						span {
							color: var(--reality-portal-color);
							font-weight: 600;
							font-size: 14px;
						}
						
						a {
							color: $text-light;
							font-size: 15px;
						}
					}
				}
			}
		}
		
		.copyright-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 30px 0;
			
			.social-icons {
				display: flex;
				flex-direction: row;
				align-items: center;
				
				p {
					color: $text-light;
					margin-bottom: 0;
					margin-right: 10px;
				}
				
				a {
					color: var(--reality-portal-color);
					font-size: 20px;
					transition: color .2s ease-in-out;
					
					&:hover {
						color: white;
					}
					
					.icon {
						padding: 0 10px;
					}
				}
			}
			
			.copyright {
				display: flex;
				flex-direction: row;
				align-items: center;
				
				p {
					color: $text-light;
					font-size: 14px;
					font-weight: 300;
					margin-bottom: 0;
					margin-right: 5px;
				}
				
				a img {
					width: 80px;
				}
				
				.author {
					display: flex;
					flex-direction: row;
					align-items: center;
				}
			}
		}
	}
	
	@media (max-width: 1200px) {
		footer {
			.main-footer {
				flex-direction: column;
				
				.footer-contact {
					margin-left: 0;
				}
			}
		}
	}
	
	@media (max-width: 992px) {
		footer {
			.main-footer {
				flex-direction: column;
				
				.logo {
					width: auto;
					margin: 0 auto;
				}
				
				.footer-contact {
					flex-direction: column;
					margin-left: 0;
					
					.contact-item:not(:last-child) {
						border: none;
						margin-bottom: 20px;
					}
				}
			}
			
			.copyright-wrapper {
				flex-direction: column;
				
				.copyright {
					flex-direction: column;
					margin-top: 20px;
					
					p {
						text-align: center;
					}
				}
				
			}
		}
	}
}
</style>